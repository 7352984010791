// @mui
import React, { useContext } from "react";
import { Box, Card, Link, Stack, Typography, useTheme } from "@mui/material";
// data
// components
import ChatMessageInput from "./message/ChatMessageInput";
import ChatHeaderDetail from "./header/ChatHeaderDetail";
import ChatMessageList from "./message/ChatMessageList";
import { useWidgetSettingsContext } from "./settings";
import { ChatContext } from "./context";
import { LoadingScreen } from "./components/loading-screen";
import { useVisualViewport } from "./hooks/use-visual-viewport";

// ----------------------------------------------------------------------

export default function Chat() {
  const settings = useWidgetSettingsContext();
  const { userLoading, user } = useContext(ChatContext);
  const theme = useTheme();
  const mobileChatHeight = useVisualViewport();

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Card
        id="widget-height"
        sx={{
          [theme.breakpoints.down("sm")]: {
            borderRadius: 0,
          },
          display: "flex",
          boxShadow: (theme) => theme.shadows[5],
          backgroundColor: "transparent",
          transition: "all 500ms ease",
          height: {
            xs: mobileChatHeight ? `${mobileChatHeight}px` : "100dvh",
            sm: `calc(100vh - 186px - ${settings.bottomSpacing}px)`,
            md: `calc(75vh - ${settings.bottomSpacing}px)`,
          },
        }}
      >
        <Stack
          flexGrow={1}
          sx={{
            overflow: "hidden",
            borderRadius: {
              xs: 0,
              sm: 2,
            },
          }}
        >
          <ChatHeaderDetail />
          <Stack
            direction="row"
            flexGrow={1}
            sx={{
              overflow: "hidden",
              borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
              backgroundColor: "background.paper",
            }}
            id="chat-widget-messages-content"
          >
            <Stack flexGrow={1} sx={{ minWidth: 0, position: "relative" }}>
              {userLoading || !user ? (
                <LoadingScreen sx={{ px: 0 }} maxWidth={140} />
              ) : (
                <>
                  <ChatMessageList />
                  <ChatMessageInput />
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Card>
      {!!settings.poweredBy && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 999,
            bottom: 0,
            boxShadow: (theme) => theme.shadows[4],
            ...(settings.position === "left"
              ? {
                  right: 0,
                }
              : {
                  left: 0,
                }),
            transform: "translateY(calc(100% + 8px))",
            px: 2,
            py: 1,
            backgroundColor: "background.paper",
            borderRadius: 2,
          }}
        >
          <Stack direction="row" gap={0.75} alignItems="center">
            <Typography
              variant="subtitle2"
              color="text.secondary"
              fontSize={14}
            >
              Made
            </Typography>
            <svg
              width="33"
              height="31"
              viewBox="0 0 33 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                height: 24,
                width: 24,
                color: theme.palette.primary.main,
              }}
            >
              <path
                d="M10.8646 0.886963L15.732 3.69717V9.3176L10.8646 12.1278L5.99713 9.3176V3.69717L10.8646 0.886963Z"
                fill="currentColor"
              />
              <path
                d="M10.8646 18.8723L15.732 21.6825V27.3029L10.8646 30.1132L5.99713 27.3029V21.6825L10.8646 18.8723Z"
                fill="currentColor"
              />
              <path
                d="M5.61847 9.87964L10.4859 12.6898V18.3103L5.61847 21.1205L0.75104 18.3103V12.6898L5.61847 9.87964Z"
                fill="currentColor"
              />
              <path
                d="M16.1107 9.87964L20.9781 12.6898V18.3103L16.1107 21.1205L11.2432 18.3103V12.6898L16.1107 9.87964Z"
                fill="currentColor"
              />
              <path
                d="M26.6009 9.87964L31.4683 12.6898V18.3103L26.6009 21.1205L21.7335 18.3103V12.6898L26.6009 9.87964Z"
                fill="currentColor"
              />
              <path
                d="M21.3548 0.886963L26.2222 3.69717V9.3176L21.3548 12.1278L16.4874 9.3176V3.69717L21.3548 0.886963Z"
                fill="currentColor"
              />
              <path
                d="M21.3548 18.8723L26.2222 21.6825V27.3029L21.3548 30.1132L16.4874 27.3029V21.6825L21.3548 18.8723Z"
                fill="currentColor"
              />
            </svg>

            <Typography
              fontSize={14}
              variant="subtitle2"
              color="text.secondary"
            >
              with
            </Typography>

            <Typography
              fontSize={14}
              variant="subtitle2"
              color="text.secondary"
            >
              <Link
                href="http://oai-widget.com/auth/firebase/register/"
                target="_blank"
              >
                agencii.com
              </Link>
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
}
