import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

export default function useNewSessionStorage<T>(
  key: string,
  initialValue: T,
  clearOnReload = true // Option to clear session storage on reload
): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.error(`Error reading sessionStorage key "${key}":`, error);
      return initialValue;
    }
  });

  const prevKeyRef = useRef(key);

  // Save to sessionStorage when value changes
  useEffect(() => {
    // Only update sessionStorage when key or value changes
    if (prevKeyRef.current !== key) {
      prevKeyRef.current = key;
    }
    try {
      if (typeof window !== "undefined") {
        window.sessionStorage.setItem(key, JSON.stringify(storedValue));
      }
    } catch (error) {
      console.error(`Error setting sessionStorage key "${key}":`, error);
    }
  }, [key, storedValue]);

  // Clear sessionStorage on page reload if needed
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (clearOnReload) {
        window.sessionStorage.removeItem(key);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [key, clearOnReload]);

  return [storedValue, setStoredValue];
}
