// @mui
import { Theme, styled } from '@mui/material/styles';
//
import { BadgeStatusValue, BadgeSizeValue } from './types';

// ----------------------------------------------------------------------

export const StyledBadgeStatus = styled('span')(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      size: BadgeSizeValue;
      status: BadgeStatusValue;
    };
  }) => {
    const { status, size } = ownerState;

    return {
      width: 10,
      height: 10,
      display: 'flex',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      '&:before, &:after': {
        content: "''",
        borderRadius: 1,
        backgroundColor: theme.palette.common.white,
      },

      ...(size === 'small' && { width: 8, height: 8 }),

      ...(size === 'large' && { width: 12, height: 12 }),

      ...(status === 'offline' && { backgroundColor: 'transparent' }),

      ...(status === 'error' && {
        backgroundColor: theme.palette.error.main,
        '&:before': { width: 6, height: 2 },
      }),

      ...(status === 'online' && {
        backgroundColor: theme.palette.success.main,
      }),

      ...(status === 'typing' && {
        backgroundColor: theme.palette.info.main,
      }),
    };
  }
);
