import { Timestamp } from "firebase/firestore";
import JSON5 from "json5";

export function parseSSEData(example: string): any {
  let parsedJson: any;
  try {
    parsedJson = JSON5.parse(example);
  } catch (error) {
    console.error("Failed to parse input using JSON5:", error);
    throw new Error("Invalid JSON format in input");
  }

  if (parsedJson.createdAt && typeof parsedJson.createdAt === "object") {
    const { seconds, nanoseconds } = parsedJson.createdAt;
    if (typeof seconds === "number" && typeof nanoseconds === "number") {
      parsedJson.createdAt = new Timestamp(seconds, nanoseconds);
    } else {
      throw new Error(
        "Invalid format for 'createdAt' field. Expected an object with 'seconds' and 'nanoseconds'."
      );
    }
  }

  return parsedJson;
}
