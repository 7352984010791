import { Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ChatContext } from "../context";
import { IConversationStarter } from "../lib/types";

interface ChatConversationStarterProps {
  starter: IConversationStarter;
}

const ChatConversationStarter = ({ starter }: ChatConversationStarterProps) => {
  const { sendMessage } = useContext(ChatContext);
  return (
    <Stack
      sx={{
        paddingY: 2,
        paddingX: 1.5,
        backgroundColor: (theme) => theme.palette.background.default,
        borderRadius: (theme) => theme.shape.borderRadius / 8,
        flex: "1 1 auto",
        minWidth: 80,
        maxHeight: 105,
        cursor: "pointer",
        overflow: "hidden",
      }}
      onClick={() => {
        sendMessage(starter.text);
      }}
    >
      <Typography
        sx={{
          // paddingY: 1,
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
        }}
      >
        {starter.text}
      </Typography>
    </Stack>
  );
};

export default ChatConversationStarter;
