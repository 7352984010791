import React, { useEffect } from "react";

import { getFirestore } from "firebase/firestore";
import type { FC } from "react";
import {
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
} from "reactfire";
import { appCheckInit, widgetAuth, widgetFirebaseApp } from "../lib/firebase";
import {
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
} from "firebase/app-check";
import { RE_CAPTCHA_ENTERPRISE_KEY } from "../config";

// HOC for FirebaseAppProvider
const withFirebaseApp = <P extends object>(Component: FC<P>): FC<P> => {
  return function WithFirebaseApp(props: P) {
    useEffect(() => {
      const appCheck = initializeAppCheck(widgetFirebaseApp, {
        provider: new ReCaptchaEnterpriseProvider(
          RE_CAPTCHA_ENTERPRISE_KEY || ""
        ),
        isTokenAutoRefreshEnabled: true,
      });
    }, [widgetFirebaseApp]);

    if (!appCheckInit) {
      return null;
    }
    return (
      <FirebaseAppProvider firebaseApp={widgetFirebaseApp}>
        <AuthProvider sdk={widgetAuth}>
          <Component {...props} />
        </AuthProvider>
      </FirebaseAppProvider>
    );
  };
};

// HOC for Providers
const withProviders = <P extends object>(Component: FC<P>): FC<P> => {
  return function WithProviders(props: P) {
    const firestoreInstance = getFirestore(widgetFirebaseApp);

    return (
      <FirestoreProvider sdk={firestoreInstance}>
        <AuthProvider sdk={widgetAuth}>
          <Component {...props} />
        </AuthProvider>
      </FirestoreProvider>
    );
  };
};

// Combining HOCs
export const withFirebaseProviders = <P extends object>(
  Component: FC<P>
): FC<P> => {
  // First wrap the component with FirebaseAppProvider
  const WithFirebaseApp = withProviders(Component);
  // Then wrap WithFirebaseApp with AuthProvider and FirestoreProvider
  return withFirebaseApp(WithFirebaseApp);
};
