import { useCallback, useEffect, useState } from "react";

export function useVisualViewport(): number {
  const [height, setHeight] = useState<number>(
    typeof window !== "undefined" && window.visualViewport
      ? window.visualViewport.height
      : window.innerHeight
  );

  const updateViewportHeight = useCallback(() => {
    if (typeof window !== "undefined") {
      const newHeight = window.visualViewport
        ? window.visualViewport.height
        : window.innerHeight;

      setHeight(newHeight);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      updateViewportHeight();

      const handleResize = () => updateViewportHeight();

      if (window.visualViewport) {
        window.visualViewport.addEventListener("resize", handleResize);

        const handleTouchMove = (e: TouchEvent) => {
          if (document.activeElement?.tagName === "INPUT") {
            e.preventDefault();
          }
        };

        window.visualViewport.addEventListener("resize", handleResize);
        window.addEventListener("touchmove", handleTouchMove, {
          passive: false,
        });

        return () => {
          window.visualViewport?.removeEventListener("resize", handleResize);
          window.removeEventListener("touchmove", handleTouchMove);
        };
      } else {
        window.addEventListener("resize", handleResize);
      }

      return () => {
        if (window.visualViewport) {
          window.visualViewport.removeEventListener("resize", handleResize);
        } else {
          window.removeEventListener("resize", handleResize);
        }
      };
    }
  }, [updateViewportHeight]);

  return height;
}
