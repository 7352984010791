import React from "react";

import Box, { BoxProps } from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

// ----------------------------------------------------------------------

interface LoadingScreenProps extends BoxProps {
  maxWidth?: number;
}

export default function LoadingScreen({
  sx,
  maxWidth,
  ...other
}: LoadingScreenProps) {
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
      {...other}
    >
      <LinearProgress
        color="primary"
        sx={{ width: 1, maxWidth: maxWidth || 360 }}
      />
    </Box>
  );
}
