import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export function timeline(theme: Theme) {
  return {
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          width: 24,
          height: 24,
          margin: 0,
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          minHeight: 48,
          "&::before": {
            padding: 0,
            flex: "none",
          },
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.divider,
          width: 1,
        },
      },
    },
  };
}
