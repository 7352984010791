import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import { getWidgetById } from "./openai-widget/lib/firestore";

export const init = async (widgetId: string, selector: string, additionalInstructions?: string) => {
  const element = document.querySelector(selector);
  if (element) {
    const root = ReactDOM.createRoot(element);

    root.render(<App widgetId={widgetId} additionalInstructions={additionalInstructions}/>);
    const widget = await getWidgetById(widgetId);

    if (!widget) {
      return {
        error: `Widget with id "${widgetId}" not found.`,
      };
    }

    return null;
  } else {
    return {
      error: `Element with selector "${selector}" not found.`,
    };
  }
};

// to check widget in development mode

// const rootElement = document.getElementById("root");
// const root = ReactDOM.createRoot(rootElement!);

// root.render(<App widgetId="spUxvxrYomyhgNDryQI6"/>);
