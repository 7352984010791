import {
  Auth,
  onAuthStateChanged,
  signInAnonymously,
  User,
} from "firebase/auth";
import { useCallback, useEffect, useMemo, useState } from "react";

import useLoadingValue, { LoadingHook } from "./use-loading-value";

export type AuthStateHook = LoadingHook<User | null, Error>;

type AuthStateOptions = {
  onUserChanged?: (user: User | null) => Promise<void>;
  enableAnonymousAuth?: boolean;
};

export default (auth: Auth, options?: AuthStateOptions): AuthStateHook => {
  const {
    error,
    loading,
    setError,
    setValue,
    value: user,
  } = useLoadingValue<User | null, Error>(() => auth.currentUser);
  const [isAnonymousAuthenticating, setIsAnonymousAuthenticating] =
    useState(false);
  useEffect(() => {
    const listener = onAuthStateChanged(
      auth,
      async (user) => {
        if (options?.onUserChanged) {
          // onUserChanged function to process custom claims on any other trigger function
          try {
            await options.onUserChanged(user);
          } catch (e) {
            setError(e as Error);
          }
        }
        setValue(user);
      },
      setError
    );

    return () => {
      listener();
    };
  }, [auth]);

  const loginAnonymous = useCallback(async () => {
    if (!options?.enableAnonymousAuth) return;
    setIsAnonymousAuthenticating(true);
    try {
      await signInAnonymously(auth);
    } catch (error) {
      console.error("Error logging in anonymously:", error);
      setError(error as Error);
    } finally {
      setIsAnonymousAuthenticating(false);
    }
  }, [auth]);

  useEffect(() => {
    if (!loading && !user && options?.enableAnonymousAuth) {
      loginAnonymous();
    }
  }, [loading, user]);

  return useMemo(() => [user, loading, error], [user, loading, error]);
};
