import React from "react";

import type { FC } from "react";
import { WidgetSettingsProvider } from "../settings";

export const withWidgetSettings = <P extends object>(Component: FC<P>): FC<P> => {
  return function WithWidgetSettings(props: P) {
    return (
      <WidgetSettingsProvider
        defaultSettings={{
          themeMode: "light", // 'light' | 'dark'
          themeDirection: "ltr", //  'rtl' | 'ltr'
          themeContrast: "bold", // 'default' | 'bold'
          themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >
        <Component {...props} />
      </WidgetSettingsProvider>
    );
  };
};
