import { SSE_ENDPOINT_URL } from "../config";

export const constructUrl = ({
  chatId,
  webAppId,
  widgetId,
  assistantId,
  filesFolderPath,
  endpoint,
  userToken,
  appCheckToken,
  additionalInstructions,
}: {
  chatId: string;
  webAppId?: string;
  widgetId?: string;
  assistantId?: string;
  filesFolderPath?: string;
  endpoint?: string;
  userToken: string;
  appCheckToken?: string;
  additionalInstructions?: string;
}): string => {
  const url = new URL(
    endpoint ||
      SSE_ENDPOINT_URL ||
      "https://agency-swarm-app-japboyzddq-uc.a.run.app/get_completion_sse/"
  );
  url.searchParams.append("assistantId", assistantId || "");
  url.searchParams.append("chatId", chatId);
  url.searchParams.append("token", userToken);
  if (filesFolderPath) {
    url.searchParams.append("messageFilesPath", filesFolderPath);
  }
  if (webAppId) {
    url.searchParams.append("webAppId", webAppId);
  }
  if (widgetId) {
    url.searchParams.append("widgetId", widgetId);
  }
  if (appCheckToken) {
    url.searchParams.append("appCheckToken", appCheckToken);
  }
  if (additionalInstructions) {
    url.searchParams.append("additionalInstructions", additionalInstructions);
  }
  return url.toString();
};
