import { useRef, useEffect, useCallback } from "react";

// ----------------------------------------------------------------------

export default function useMessagesScroll(messages: any[], skip?: boolean) {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollMessagesToBottom = useCallback(() => {
    if (!messages.length || skip) {
      return;
    }

    if (!messagesEndRef.current) {
      return;
    }

    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTo({
        top: messagesEndRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages?.length, skip]);

  useEffect(() => {
    scrollMessagesToBottom();
  }, [messages?.length, skip]);

  return {
    messagesEndRef,
  };
}
