const cleanUpStringFields = (input: any): any => {
  if (Array.isArray(input)) {
    return input.map(cleanUpStringFields); // Recursively clean array elements
  }

  if (typeof input === "object" && input !== null) {
    const cleanedObject: { [key: string]: any } = {};
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        cleanedObject[key] = cleanUpStringFields(input[key]); // Recursively clean object fields
      }
    }
    return cleanedObject;
  } else if (typeof input === "string") {
    const cleanedString = input
      .replace(/\\r\\n/g, "\n") // Replace literal escaped \r\n with newline
      .replace(/\r\n/g, "\n") // Replace actual \r\n with newline
      .replace(/\\n/g, "\n") // Replace escaped \n with newline
      .replace(/\n/g, "\n") // Replace literal \n with newline
      .replace(/\\t/g, " ") // Replace escaped \t with space
      .replace(/\\'/g, "'") // Replace escaped single quotes
      .replace(/\\"/g, '"') // Replace escaped double quotes
      .replace(/\\\\/g, "\\") // Replace escaped backslashes
      .replace(/\s+/g, " ") // Condense multiple spaces into one
      .trim(); // Trim leading and trailing spaces
    return cleanedString;
  }

  return input; // Return non-string fields as is
};

export function parseAndFormatRawRequest(rawRequest: string): string {
  try {
    const cleanedRawRequest = rawRequest
      .replace(/\\r\\n/g, "\n") // Remove escaped \r\n
      .replace(/\r\n/g, "\n") // Remove actual \r\n
      .replace(/\\n/g, "\n") // Remove escaped \n
      .replace(/\t/g, " ") // Replace tabs with spaces
      .replace(/\\\\/g, "\\") // Replace double backslashes with single backslash
      .trim(); // Trim leading/trailing spaces

    const parsedRequest = JSON.parse(cleanedRawRequest);

    const cleanedRequest = cleanUpStringFields(parsedRequest);

    return JSON.stringify(cleanedRequest, null, 2);
  } catch (error) {
    console.error("Error parsing raw request:", error);
    return rawRequest;
  }
}
export function parseInput(str: string): { [key: string]: string | null } | null {
  try {
    const parsedRequest = JSON.parse(str);
    const result: { [key: string]: string } = {};

    // Iterate through all keys in the parsed object
    for (const key in parsedRequest) {
      // If the value is null or undefined, set it to '-'
      const value = parsedRequest[key];
      result[key] =
        value !== undefined && value !== null ? JSON.stringify(value) : "-";
    }
    if (Object.keys(result).length === 0) {
      return null;
    }
    // Return the result object with all keys and their parsed values

    return result;
  } catch (err) {
    // If parsing fails, return a generic result with '-'
    return null;
  }
}

