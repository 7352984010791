import React from "react";
import { WidgetLayoutWithSettings } from "./openai-widget/layout";
import WidgetChat from "./openai-widget";

import "./index.css";

export const App = ({ widgetId, additionalInstructions }: { widgetId: string, additionalInstructions?: string; }) => {
  // const isSharedPrototype = window.location.href.includes("widget-prototype");
  return (
    <WidgetLayoutWithSettings additionalInstructions={additionalInstructions} widgetId={widgetId}>
      <WidgetChat />
    </WidgetLayoutWithSettings>
  );
};
