import {
  collection,
  query,
  where,
  CollectionReference,
} from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { useEffect, useMemo, useRef } from "react";
import { ChatDoc, Message } from "../types";

interface UseChatMessagesResult {
  messagesData: Message[];
  status: "loading" | "error" | "success";
}

const useChatMessages = ({
  widgetId,
  selectedChatId,
  skip = false,
}: {
  widgetId: string | null | undefined;
  selectedChatId: string | null | undefined;
  skip?: boolean;
}): UseChatMessagesResult => {
  const firestore = useFirestore();
  const lastMessages = useRef<Message[]>([]);

  const chatCollectionRef = collection(
    firestore,
    `widgets/${widgetId || "1"}/chats`
  ) as CollectionReference<ChatDoc>;

  const filteredQuery = query(
    chatCollectionRef,
    where("chatId", "==", selectedChatId || "1")
  );

  const { data: messages, status: messagesStatus } = useFirestoreCollectionData(
    filteredQuery,
    {
      idField: "id",
    }
  );

  useEffect(() => {
    if (!skip && messages?.length) {
      lastMessages.current = messages.flatMap((chat) => chat.messages || []);
    }
  }, [skip, messages]);

  const currentMessages = useMemo(() => {
    return skip
      ? lastMessages.current
      : messages?.flatMap((chat) => chat.messages || []);
  }, [skip, messages]);

  const memoizedResult = useMemo(() => {
    return {
      messagesData: currentMessages,
      status: messagesStatus,
    };
  }, [currentMessages, messagesStatus]);

  return memoizedResult;
};

export default useChatMessages;
