import React from "react";

import { useContext, useState } from "react";
// @mui
import { InputBase, IconButton, Stack } from "@mui/material";
// components
import { ChatContext } from "../context";
import { useWidgetSettingsContext } from "../settings";
import Iconify from "../components/iconify";

// ----------------------------------------------------------------------

export default function ChatMessageInput() {
  const [message, setMessage] = useState("");
  const { status, sendMessage } = useContext(ChatContext);
  const settings = useWidgetSettingsContext();

  const handleSend = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.stopPropagation();
    const trimmedMessage = message?.trim();
    if (event.key === "Enter" && status !== "typing") {
      if (trimmedMessage) {
        sendMessage(message);
      }
      setMessage("");
    }
  };

  const handleClickSend = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      sendMessage(message);
    }
    setMessage("");
  };

  return (
    <InputBase
      value={message}
      multiline
      maxRows={4}
      onKeyUp={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          handleSend(e);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
        }
      }}
      onChange={(event) => setMessage(event.target.value)}
      placeholder={settings.inputPlaceholder}
      endAdornment={
        <Stack height={1} marginLeft={1}>
          <IconButton
            disabled={status === "typing" || !message.trim()?.length}
            size="small"
            onClick={handleClickSend}
            sx={{
              mt: 1,
            }}
          >
            <Iconify icon="ic:baseline-send" />
          </IconButton>
        </Stack>
      }
      sx={{
        px: 2,
        minHeight: 56,
        flexShrink: 0,
        borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        "& input::placeholder": {
          fontWeight: 500,
        },
      }}
      id="chat-widget-input-wrapper"
    />
  );
}
