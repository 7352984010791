import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface MarkdownImageProps {
  [x: string]: any;
}

const MarkdownImage = (props: MarkdownImageProps) => {
  const [imgSrc, setImgSrc] = useState(props?.src);

  return (
    <LazyLoadImage
      src={imgSrc}
      alt={props.alt}
      effect="blur"
      onError={() => setImgSrc("/assets/placeholder.svg")}
      placeholderSrc="/assets/placeholder.svg"
      style={{
        aspectRatio: "16/9",
        borderRadius: "16px",
        objectFit: "cover",
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "800px",
      }}
    />
  );
};

export default MarkdownImage;
